import React from 'react';
import { Link as RouterLink, NavLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import dochakralogo from '../images/logos/doChakra_logo.webp'

function NavBar() {
  return (
    <AppBar position="fixed">
      <Toolbar style={{ justifyContent: 'space-between' }}>
        <Box display="flex">
          <NavLink to="/" className="nav-link" activeclassname="active">Home</NavLink>
          <NavLink to="/articles" className="nav-link" activeclassname="active">Learn</NavLink> {/* Add Articles link */}

          {/*<NavLink to="/listings" className="nav-link" activeclassname="active">Explore Bikes</NavLink>*/}
        </Box>
        <img src={dochakralogo} alt="Dochakra Logo" style={{ height: '80px' }} />
        <Box display="flex">
          {/* Add more links if needed */}
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default NavBar;

