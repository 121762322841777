import React, { useState, useEffect } from 'react';
import { Container, Grid } from '@mui/material';
import BikeFilters from './BikeFilters';
import BikeListingsGrid from './BikeListingsGrid';

function BikeListings() {
  const [bikes, setBikes] = useState([]);
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [priceRange, setPriceRange] = useState([0, 100000]);

  useEffect(() => {
    fetchBrands();
    fetchBikes();
  }, []);

  const fetchBrands = () => {
    fetch('http://localhost:8080/bikes/getBrands')
      .then(response => response.json())
      .then(setBrands)
      .catch(error => console.error('Error fetching brands:', error));
  };

  const fetchBikes = () => {
    const url = new URL("http://localhost:8080/bikes/getListings");

    url.searchParams.append('brands', selectedBrands.join(","));
    url.searchParams.append('minPrice', priceRange[0]);
    url.searchParams.append('maxPrice', priceRange[1]);

    fetch(url)
      .then(response => response.json())
      .then(setBikes)
      .catch(error => console.error('Error fetching bikes:', error));
  };



  return (
<Container maxWidth={false}>
      <Grid container spacing={3} sx={{ paddingTop: 2, paddingBottom: 2 }}> {/* Adjust top and bottom padding for overall container */}
        <Grid item xs={12} sm={3} sx={{ paddingRight: 2 }}> {/* Add right padding to create space between filters and grid */}
          <BikeFilters
            brands={brands}
            selectedBrands={selectedBrands}
            setSelectedBrands={setSelectedBrands}
            priceRange={priceRange}
            setPriceRange={setPriceRange}
            fetchBikes={fetchBikes}
          />
        </Grid>
        <Grid item xs={12} sm={9} sx={{ paddingLeft: 2 }}> {/* Add left padding to create space between grid and filters */}
          <BikeListingsGrid bikes={bikes} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default BikeListings;
