import React from 'react';
import styled from 'styled-components';

const articles = [
  { id: 1, title: 'The Benefits of E-Bikes', url: '/articles/benefits_of_ebikes.html' },
  { id: 2, title: 'Top E-Bikes in 2024', url: '/articles/top_ebikes_2024.html' },
  { id: 3, title: 'How to Maintain Your E-Bike', url: '/articles/how_to_maintain_your_ebike.html' }
];

const ArticlesContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: auto;
  font-family: 'Arial', sans-serif;
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  font-size: 2em;
  color: #333;
`;

const ArticleList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ArticleItem = styled.li`
  background: #f9f9f9;
  margin: 10px 0;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }

  a {
    text-decoration: none;
    color: #007bff;
    font-weight: bold;
    transition: color 0.2s;

    &:hover {
      color: #0056b3;
    }
  }
`;

const Articles = () => {
  return (
    <ArticlesContainer>
      <Title>Articles on E-Bikes</Title>
      <ArticleList>
        {articles.map((article) => (
          <ArticleItem key={article.id}>
            <a href={article.url} target="_blank" rel="noopener noreferrer">{article.title}</a>
          </ArticleItem>
        ))}
      </ArticleList>
    </ArticlesContainer>
  );
};

export default Articles;
