import React from 'react';
import {
  Typography, Box, Slider, Accordion, AccordionSummary, AccordionDetails, Checkbox,
  FormControlLabel, List, ListItem, Button, Chip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const BikeFilters = ({
    brands, selectedBrands, setSelectedBrands, priceRange, setPriceRange, fetchBikes
  }) => {
    const handleBrandChange = (brand, isChecked) => {
      setSelectedBrands(prev => isChecked ? [...prev, brand] : prev.filter(b => b !== brand));
    };
  
    const handleDeleteBrandChip = (brand) => {
      setSelectedBrands(prev => prev.filter(b => b !== brand));
      fetchBikes();
    };
  
    const handlePriceChange = (event, newValue) => {
      setPriceRange(newValue);
    };
  
    const handleDeletePriceChip = (event) => {
      setPriceRange([0, 100000]);
      fetchBikes();
    };

  return (
    <Box className="filter-container" sx={{ width: '100%', padding: '16px', background: '#fff' }}>
      {/* Active Filters Display */}
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
        {selectedBrands.map(brand => (
          <Chip
            key={brand}
            label={brand}
            onDelete={() => handleDeleteBrandChip(brand)}
            color="primary"
          />
        ))}
        {(priceRange[0] !== 0 || priceRange[1] !== 100000) && (
          <Chip
            label={`Price: $${priceRange[0]} - $${priceRange[1]}`}
            onDelete={() => handleDeletePriceChip()}
            color="secondary"
          />
        )}
      </Box>        
      {/* Brands Filter */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Brands:</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List style={{ maxHeight: 200, overflow: 'auto' }}>
            {brands.map((brand) => (
              <ListItem key={brand}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedBrands.includes(brand)}
                      onChange={(e) => handleBrandChange(brand, e.target.checked)}
                      name={brand}
                    />
                  }
                  label={brand}
                />
              </ListItem>
            ))}
          </List>
        </AccordionDetails>
      </Accordion>

      {/* Price Range Filter */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Price Range</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Slider
            value={priceRange}
            onChange={handlePriceChange}
            valueLabelDisplay="auto"
            min={0}
            max={100000}
            sx={{ width: '90%' }}
          />
          <Typography>${priceRange[0]} - ${priceRange[1]}</Typography>
        </AccordionDetails>
      </Accordion>

      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
        <Button variant="contained" color="primary" onClick={fetchBikes} sx={{ marginBottom: 2 }}>
            Apply Filters
        </Button>
      </Box>
    </Box>
  );
};

export default BikeFilters;
