import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/BikeStaticList';
import BikeListings from './components/BikeListings';
import NavBar from './components/NavBar';
import Articles from './components/Articles'; 

import './styles/styles.css'; // Ensure the stylesheet is imported
 // Ensure the stylesheet is imported
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '8px', // Reduce padding
          paddingRight: '8px', // Reduce padding
        }
      }
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <div>
        <NavBar />
        <div className="route-container" style={{ marginTop: '64px' }}>
          <Routes>
          
          <Route path="/" element={<HomePage />} />
          <Route path="/listings" element={<BikeListings />} />
          <Route path="/articles" element={<Articles />} /> 
          </Routes>
        </div>
      </div>
    </Router>
    </ThemeProvider>
  );
}

export default App;
