import React from 'react';
import { Grid, Card, CardContent, CardMedia, Typography } from '@mui/material';

const BikeListingsGrid = ({ bikes }) => (
  <Grid container spacing={2}>
    {bikes.map(bike => (
      <Grid item key={bike.id} xs={12} sm={6} md={4} lg={3}>
        <Card className="card" sx={{ height: '100%', margin: '8px', padding: '16px' }}>
          <CardMedia
            component="img"
            height="140"
            image={bike.imgLink}
            alt={`Image of ${bike.model}`}
          />
          <CardContent>
            <Typography variant="h4" component="h2">{bike.brand}</Typography>
            <Typography variant="h5" component="h2">{bike.model}</Typography>
            <Typography variant="body2" color="text.secondary">{bike.shortDescription}</Typography>
          </CardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
);

export default BikeListingsGrid;
