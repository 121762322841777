import React from 'react';
import '../styles/BikesList.css';
import * as Logos from '../images/logos';

const bikes = [
  { name: 'Addmotor', link: 'https://www.addmotor.com/', imgSrc: Logos.AddmotorLogo, bg: 'white-bg' },
  { name: 'Alizeti', link: 'https://www.alizetibikes.com/', bg: 'white-bg' },
  { name: 'Ancheer', link: 'https://ancheer.shop/', imgSrc: Logos.AncheerLogo, bg: 'black-bg' },
  { name: 'Ariel Rider', link: 'https://arielrider.com/', imgSrc: Logos.ArielRiderLogo, bg: 'white-bg' },
  { name: 'Azzuro Bikes', link: 'https://azzurobikes.com/', imgSrc: Logos.AzzuroBikesLogo, bg: 'white-bg' },
  { name: 'Bakcou', link: 'https://bakcou.com/', imgSrc: Logos.BakcouLogo, bg: 'black-bg' },
  { name: 'Batch Bicycles', link: 'https://www.batchbicycles.com/', imgSrc: Logos.BatchBicyclesLogo, bg: 'black-bg' },
  { name: 'Besv', link: 'https://besv.com/', imgSrc: Logos.BesvLogo, bg: 'white-bg' },
  { name: 'Bianchi', link: 'https://www.bianchi.com/', imgSrc: Logos.BianchiLogo, bg: 'black-bg' },
  { name: 'Biktrix', link: 'https://www.biktrix.com/', imgSrc: Logos.BiktrixLogo, bg: 'white-bg' },
  { name: 'Bintelli', link: 'https://www.bintelli.com/electric-bikes/', imgSrc: Logos.BintelliLogo, bg: 'white-bg' },
  { name: 'Blix', link: 'https://blixbike.com/', imgSrc: Logos.BlixLogo, bg: 'white-bg' },
  { name: 'Bluejay', link: 'https://bluejaybikes.com/', imgSrc: Logos.BluejayLogo, bg: 'white-bg' },
  { name: 'Bmc', link: 'https://us.bmc-switzerland.com/', imgSrc: Logos.BmcLogo, bg: 'black-bg' },
  { name: 'Brompton', link: 'https://us.brompton.com/', imgSrc: Logos.BromptonLogo, bg: 'white-bg' },
  { name: 'Bulls', link: 'https://www.bullsbikesusa.com/', imgSrc: Logos.BullsLogo, bg: 'black-bg' },
  { name: 'Cannondale', link: 'https://www.cannondale.com/', imgSrc: Logos.CannondaleLogo, bg: 'white-bg' },
  { name: 'Canyon', link: 'https://www.canyon.com/en-us/electric-bikes/', imgSrc: Logos.CanyonLogo, bg: 'white-bg' },
  { name: 'Carbo', link: 'https://ridecarbo.com/', imgSrc: Logos.CarboLogo, bg: 'black-bg' },
  { name: 'Cero', link: 'https://cero.bike/', imgSrc: Logos.CeroLogo, bg: 'black-bg' },
  { name: 'Cervelo', link: 'https://www.cervelo.com/en-US', imgSrc: Logos.CerveloLogo, bg: 'white-bg' },
  { name: 'Charge', link: 'https://www.chargebikes.com/', imgSrc: Logos.ChargeLogo, bg: 'white-bg' },
  { name: 'Co-op Cycles', link: 'https://www.rei.com/b/co-op-cycles', imgSrc: Logos.CoOpLogo, bg: 'white-bg' },
  { name: 'Conway', link: 'https://www.conway-bikes.com/en/e-bikes/', imgSrc: Logos.ConwayLogo, bg: 'white-bg' },
  { name: 'Corratec', link: 'https://www.corratec.com/bikes/e-bikes/', imgSrc: Logos.CorratecLogo, bg: 'black-bg' },
  { name: 'Cowboy', link: 'https://us.cowboy.com/', imgSrc: Logos.CowboyLogo, bg: 'white-bg' },
  { name: 'Cube', link: 'https://www.cube.eu/', imgSrc: Logos.CubeLogo, bg: 'white-bg' },
  { name: 'Delfast', link: 'https://us.delfastbikes.com/', imgSrc: Logos.DelfastLogo, bg: 'black-bg' },
  { name: 'Ducati', link: 'https://www.ducati.com/us/en/e-bike', imgSrc: Logos.DucatiLogo, bg: 'white-bg' },
  { name: 'Ecotric', link: 'https://www.ecotric.com/', imgSrc: Logos.EcotricLogo, bg: 'white-bg' },
  { name: 'Elby', link: 'https://elbymobility.com/', imgSrc: Logos.ElbyLogo, bg: 'black-bg' },
  { name: 'Electra', link: 'https://electra.trekbikes.com/us/en_US/', imgSrc: Logos.ElectraLogo, bg: 'white-bg' },
  { name: 'Emojo', link: 'https://emojobike.com/', imgSrc: Logos.EmojoLogo, bg: 'white-bg' },
  { name: 'Engwe', link: 'https://engwe-bikes.com/', imgSrc: Logos.EngweLogo, bg: 'black-bg' },
  { name: 'Eunorau', link: 'https://eunorau-ebike.com/', imgSrc: Logos.EunorauLogo, bg: 'white-bg' },
  { name: 'Eskute', link: 'https://eskute.com/', imgSrc: Logos.EskuteLogo, bg: 'white-bg' },
  { name: 'Euphree', link: 'https://euphree.com/', imgSrc: Logos.EuphreeLogo, bg: 'black-bg' },
  { name: 'Evelo', link: 'https://evelo.com/', imgSrc: Logos.EveloLogo, bg: 'white-bg' },
  { name: 'Felt', link: 'https://www.feltbicycles.com/en-us.html', imgSrc: Logos.FeltLogo, bg: 'white-bg' },
  { name: 'Fiido', link: 'https://fiido.com/', imgSrc: Logos.FiidoLogo, bg: 'black-bg' },
  { name: 'Focus', link: 'https://www.focus-bikes.com/', imgSrc: Logos.FocusLogo, bg: 'white-bg' },
  { name: 'Gazelle', link: 'https://www.gazellebikes.com/en-us/bikes/ebikes', imgSrc: Logos.GazelleLogo, bg: 'white-bg' },
  { name: 'Giant', link: 'https://www.giant-bicycles.com/us', imgSrc: Logos.GiantLogo, bg: 'white-bg' },
  { name: 'Haibike', link: 'https://www.haibike.com/de/en', imgSrc: Logos.HaibikeLogo, bg: 'white-bg' },
  { name: 'Heybike', link: 'https://www.heybike.com/', imgSrc: Logos.HeybikeLogo, bg: 'black-bg' },
  { name: 'Himiway', link: 'https://himiwaybike.com/', imgSrc: Logos.HimiwayLogo, bg: 'white-bg' },
  { name: 'iBikeDirect', link: 'https://www.bikesdirect.com/products/ebikes-electric-bikes-at-near-wholesale.html', bg: '' },
  { name: 'Juiced Bikes', link: 'https://www.juicedbikes.com/', imgSrc: Logos.JuicedBikesLogo, bg: 'white-bg' },
  { name: 'KBO', link: 'https://kbo.bike/', bg: '' },
  { name: 'Khs', link: 'https://www.khsbicycles.com/', imgSrc: Logos.KHSLogo, bg: 'black-bg' },
  { name: 'Lektro', link: 'https://lektrobike.com/', bg: '' },
  { name: 'Lectric E-Bikes', link: 'https://lectricebikes.com/', imgSrc: Logos.LectricLogo,bg: '' },
  { name: 'Linus', link: 'https://www.linusbike.com/collections/e-bikes',imgSrc: Logos.LinusLogo, bg: '' },
  { name: 'Liv', link: 'https://liv-cycling.com/', imgSrc: Logos.LivLogo, bg: 'white-bg' },
  { name: 'Magnum', link: 'https://www.magnumbikes.com/', imgSrc: Logos.MagnumLogo, bg: 'black-bg' },
  { name: 'Mohsin E-Bikes', link: 'https://mohsinebikes.com/', bg: '' },
  { name: 'Momentum', link: 'https://momentumbiking.com/', bg: '' },
  { name: 'Nakto', link: 'https://nakto.com/', imgSrc: Logos.NaktoLogo, bg: 'white-bg' },
  { name: 'Ncm', link: 'https://ncmbikes.com/', imgSrc: Logos.NcmLogo, bg: 'black-bg' },
  { name: 'Niner', link: 'https://www.ninerbikes.com/', imgSrc: Logos.NinerLogo, bg: 'white-bg' },
  { name: 'No-Boundries (indiegogo)', link: 'https://www.indiegogo.com/projects/voyager1-world-class-mid-drive-all-terrain-e-bike#/', imgSrc: Logos.NoBoLogo, bg: 'black-bg' },
  { name: 'Orbea', link: 'https://www.orbea.com/us-en/', imgSrc: Logos.OrbeaLogo, bg: 'white-bg' },
  { name: 'Pedego', link: 'https://pedegoelectricbikes.com/', imgSrc: Logos.PedegoLogo, bg: 'white-bg' },
  { name: 'Pivot', link: 'https://www.pivotcycles.com/en/', imgSrc: Logos.PivotLogo, bg: 'white-bg' },
  { name: 'Propella', link: 'https://www.propella.bike/', imgSrc: Logos.PropellaLogo, bg: 'black-bg' },
  { name: 'Quietkat', link: 'https://quietkat.com/', imgSrc: Logos.QuietkatLogo, bg: 'black-bg' },
  { name: 'Rad Power Bikes', link: 'https://www.radpowerbikes.com/', imgSrc: Logos.RadPowerBikesLogo, bg: 'white-bg' },
  { name: 'Raleigh', link: 'http://www.raleigh-cycles.com/', imgSrc: Logos.RahleighLogo, bg: 'white-bg' },
  { name: 'Rambo', link: 'https://www.rambobikes.com/', imgSrc: Logos.RamboLogo, bg: 'white-bg' },
  { name: 'Revi', link: 'https://www.revibikes.com/', imgSrc: Logos.ReviLogo, bg: 'black-bg' },
  { name: 'Ride1up', link: 'https://ride1up.com/', imgSrc: Logos.Ride1UpLogo, bg: 'white-bg' },
  { name: 'Riese & Muller', link: 'https://www.r-m.de/en-dk/', imgSrc: Logos.RieseMullerLogo, bg: 'white-bg' },
  { name: 'Roodog', link: 'https://www.roodog.co.uk/', imgSrc: Logos.RoodogLogo, bg: 'white-bg' },
  { name: 'Rungu', link: 'https://riderungu.com/', imgSrc: Logos.RunguLogo, bg: 'white-bg' },
  { name: 'Salsa Cycles', link: 'https://www.salsacycles.com/', imgSrc: Logos.SalsaLogo, bg: 'white-bg' },
  { name: 'Santa Cruz', link: 'https://www.santacruzbicycles.com/en-US', imgSrc: Logos.SantaCruzLogo, bg: 'white-bg' },
  { name: 'Schwinn', link: 'https://www.schwinnbikes.com/', imgSrc: Logos.SchwinnLogo, bg: 'white-bg' },
  { name: 'Scott', link: 'https://www.scott-sports.com/us/en/products/bike-ebikes', imgSrc: Logos.ScottLogo, bg: 'white-bg' },
  { name: 'Serial 1', link: 'https://serial1.com/', imgSrc: Logos.Serial1Logo, bg: 'black-bg' },
  { name: 'Sixthreezero', link: 'https://www.sixthreezero.com/', imgSrc: Logos.SixThreeZeroLogo, bg: 'white-bg' },
  { name: 'Specialized', link: 'https://www.specialized.com/us/en/c/ebikes', imgSrc: Logos.SpecializedLogo, bg: 'white-bg' },
  { name: 'Stark Drive', link: 'https://starkdrive.bike/', imgSrc: Logos.StarkDriveLogo, bg: 'black-bg' },
  { name: 'Stealth', link: 'https://www.stealthelectricbikes.com/', imgSrc: Logos.StealthLogo, bg: 'black-bg' },
  { name: 'Stromer', link: 'https://us.stromerbike.com/', imgSrc: Logos.StromerLogo, bg: 'white-bg' },
  { name: 'Super73', link: 'https://super73.com/', imgSrc: Logos.Super73Logo, bg: 'black-bg' },
  { name: 'Superhuman', link: 'https://superhumanbikes.com/', imgSrc: Logos.SuperhumanLogo, bg: 'black-bg' },
  { name: 'Surface 604', link: 'https://surface604bikes.com/', imgSrc: Logos.Surface604Logo, bg: 'black-bg' },
  { name: 'Swagtron', link: 'https://swagtron.com/electric-bikes', imgSrc: Logos.SwagtronLogo, bg: 'white-bg' },
  { name: 'Tern', link: 'https://www.ternbicycles.com/us', imgSrc: Logos.TernLogo, bg: 'white-bg' },
  { name: 'The Rad Projects', link: 'https://www.radpowerbikes.com/', bg: '' },
  { name: 'Trek', link: 'https://www.trekbikes.com/us/en_US/bikes/electric-bikes/c/B507/', imgSrc: Logos.TrekLogo, bg: 'white-bg' },
  { name: 'Vanmoof', link: 'https://www.vanmoof.com/en-US', imgSrc: Logos.VanmoofLogoReal, bg: 'white-bg' },
  { name: 'Velotric', link: 'https://www.velotricbike.com/', imgSrc: Logos.VelotricLogo, bg: 'white-bg' },
  { name: 'Velowave', link: 'https://www.velowavebikes.com/', imgSrc: Logos.VelowaveLogo, bg: 'white-bg' },
  { name: 'Viavolt', link: 'https://vvolt.com/', imgSrc: Logos.ViavoltLogo, bg: 'black-bg' },
  { name: 'X-treme', link: 'https://x-tremescooters.com/', imgSrc: Logos.Xtremelogo, bg: 'white-bg' },
  { name: 'Yamaha', link: 'https://yamaha-motor.com/shop/e-bike/ebike-models', imgSrc: Logos.YamahaLogo, bg: 'white-bg' },
  { name: 'Yuba', link: 'https://yubabikes.com/', imgSrc: Logos.YubaLogo, bg: 'black-bg' },
  { name: 'Zuga', link: 'https://zugobike.com/', imgSrc: Logos.ZugoLogo, bg: 'white-bg' },
];

const BikesList = () => (
  <div className="bikes-list">
    {bikes.map((bike, index) => (
      <div key={index} className={`tile ${bike.bg}`}>
        <a href={bike.link} target="_blank" rel="noopener noreferrer">
          {bike.imgSrc ? (
            <img src={bike.imgSrc} alt={`${bike.name} Logo`} />
          ) : (
            bike.name
          )}
        </a>
      </div>
    ))}
  </div>
);

export default BikesList;
